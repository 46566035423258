.dashboard {
  @apply w-full h-screen bg-grey-200 overflow-hidden;
  padding: 16px 16px 16px 0;

  &__container {
    @apply h-full;
  }

  &__sidebar {
    &__expanded-section {
      width: calc(27% - 74px);
      padding: 0 35px 30px 18px;
      overflow-y: scroll;
      &::-webkit-scrollbar-thumb {
        display: none;
      }
      &:hover::-webkit-scrollbar-thumb {
        display: block;
      }
      height: 100%;
      @media screen and (max-width: 1500px) {
        width: calc(22.2% - 74px);
      }

      @media screen and (min-width: 1500px) {
        width: calc(22.2% - 74px);
      }
    }

    .channels-list {
      @apply p-3 py-2 cursor-pointer;
      border-radius: 10px;

      &:hover,
      &.active {
        @apply bg-grey-100;
        transition: all 300ms;
      }

      &__icon-wrapper {
        width: 32px;
        height: 32px;
        @apply bg-white p-2;
        box-shadow: inset 0px 0px 0px 1px rgba(0, 92, 111, 0.1);
        border-radius: 100%;

        > img {
          @apply w-full h-full overflow-hidden;
        }
      }

      .p-10 {
        padding: 10px;
      }
    }

    &__nav-list {
      @apply relative flex items-start gap-3 bg-transparent cursor-pointer;
      padding-top: 9px;
      padding-bottom: 9px;
      border-radius: 10px;
      transition: all 300ms ease-in;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      &__title {
        @apply font-medium;
        font-size: 15px;
      }

      &:not(.no-fill) svg path {
        fill: #4c5254;
        transition: all 300ms ease-in;
      }

      .active {
        width: calc(100% + 24px);
        @apply absolute h-full bg-grey-50 top-0 opacity-0 invisible;
        left: -6px;
        border-radius: 10px;
        z-index: -1;
        transition: all 300ms ease-in;
      }

      &::after {
        content: '';
        width: calc(100% + 24px);
        @apply absolute h-full bg-grey-55 top-0 opacity-0 invisible;
        left: -6px;
        border-radius: 10px;
        z-index: -1;
        transition: all 300ms ease-in;
      }

      &:active::after {
        opacity: 1;
        visibility: visible;
      }

      &:hover::after,
      &.active::after {
        @apply opacity-100 visible;
      }

      &:not(.no-fill):hover svg path,
      &:not(.no-fill).active svg path {
        fill: #005c6f;
      }

      &:hover &,
      &.active & {
        &__title {
          @apply font-semibold;
        }
      }

      &--type2 {
        .dashboard__sidebar__nav-list {
          .avatar-container {
            width: 32px;
            height: 32px;
            @apply bg-white;
            box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
          }

          &.active {
            .avatar-container {
              box-shadow: inset 0px 0px 0px 1px rgba(0, 92, 111, 0.1);
            }
          }
        }
      }
    }
    &__nav-list-disabled {
      @apply relative flex items-start gap-3 cursor-not-allowed;
      padding-top: 9px;
      padding-bottom: 9px;
      border-radius: 10px;
      transition: all 300ms ease-in;
      z-index: 1;
      // color: gray!important;
      figure {
        background-color: gray !important;
      }
      &:not(:last-child) {
        margin-bottom: 2px;
      }

      &__title {
        @apply font-medium;
        font-size: 15px;
      }

      svg path {
        fill: #4c5254;
        transition: all 300ms ease-in;
      }

      &::after {
        content: '';
        width: calc(100% + 24px);
        @apply absolute h-full top-0 opacity-0 invisible;
        left: -12px;
        border-radius: 10px;
        z-index: -1;
        transition: all 300ms ease-in;
      }

      &:hover::after {
        background-color: rgb(128, 128, 128, 0.1) !important;
        @apply opacity-100 visible;
        color: gray;
      }

      &:hover svg path,
      &.active svg path {
        fill: gray;
      }

      &:hover &,
      &.active & {
        &__title {
          @apply font-semibold;
        }
      }

      &--type2 {
        .dashboard__sidebar__nav-list-disabled {
          .avatar-container {
            width: 32px;
            height: 32px;
            @apply bg-white;
            box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
          }

          &.active {
            .avatar-container {
              box-shadow: inset 0px 0px 0px 1px rgba(0, 92, 111, 0.1);
            }
          }
        }
      }
    }
  }

  .things-todo-icon path {
    fill: transparent !important;
  }
  .box-drag-drop-container {
    &:hover,
    &:active {
      .box-drag-drop-icon {
        opacity: 1;
        visibility: visible;

        &:hover,
        &:active {
          + .dashboard__sidebar__nav-list {
            &::after {
              @apply opacity-100 visible;
            }
          }
        }
      }
    }
  }

  .box-drag-drop-icon {
    opacity: 0;
    visibility: hidden;
  }

  &__sidebar__links {
    @apply h-full flex flex-col;
    width: 74px;
    padding: 0 13px;

    &__list {
      @apply flex items-center justify-center bg-transparent cursor-pointer;
      width: 48px;
      height: 48px;
      margin-bottom: 6px;
      border-radius: 10px;
      transition: all 300ms;

      &:hover:not(.circle),
      &.active:not(.circle) {
        @apply bg-grey-55;

        svg path {
          fill: #005c6f;
          // stroke: #005c6f;
        }
        svg rect {
          fill: #005c6f;
          // stroke: #005c6f;
        }
        .toolbox-icon {
          path {
            stroke: #005c6f;
          }
          rect {
            stroke: #005c6f;
          }
        }
        svg rect {
          fill: #005c6f;
        }
      }

      &:hover:not(.circle) svg path,
      &.active:hover:not(.circle) svg path {
        fill: #005c6f;
        // stroke: #005c6f;
      }
      &:hover svg rect,
      &.active:hover svg rect {
        fill: #005c6f;
        stroke: #005c6f;
      }

      &.circle {
        &:hover,
        &:active:hover {
          @apply bg-grey-55;
          svg circle {
            fill: #005c6f;
            stroke: #005c6f;
          }
        }
      }
      &--icon-leaf {
        &:hover svg path,
        &.active:hover svg path {
          stroke: #005c6f;
          fill: unset;
        }
      }
      &_settings {
        &:hover:not(.oval) svg path,
        &.active:hover:not(.oval) svg path {
          fill: #005c6f;
          stroke: #005c6f;
        }
      }
    }

    &__no-fill {
      @apply flex items-center justify-center bg-transparent cursor-pointer;
      width: 48px;
      height: 48px;
      margin-bottom: 6px;
      border-radius: 10px;
      transition: all 300ms;

      &:hover:not(.circle),
      &.active:not(.circle) {
        @apply bg-grey-55;
      }

      &.circle {
        &:hover,
        &:active:hover {
          @apply bg-grey-55;
        }
      }
    }

    .ebox-new-icon {
      svg rect {
        fill: unset !important; /* or any other value you want */
        stroke: unset !important; /* or any other value you want */
      }
    }
  }

  .ebox-icon-count {
    @apply absolute flex justify-center items-center bg-red-850 rounded-md;
    top: 6px;
    left: 25px;
    width: 14px;
    height: 14px;
    font-size: 9px;
    color: white;
    font-weight: 700;
  }

  &__main {
    width: 73%;
    @apply relative flex-1 h-full ml-0 bg-white overflow-hidden;
    box-shadow: 0px 4px 30px rgba(85, 83, 80, 0.15);
    border-radius: 14px;

    &__header {
      padding: 25px 32px 25px 40px;
      @apply flex items-center justify-between border-b border-grey-400;

      &__lead-detail {
        @media screen and (max-width: 1500px) {
          width: calc(36% + 18px);
        }

        @media screen and (min-width: 1500px) {
          width: calc(31% + 2px);
        }
      }
      .avatar-container {
        width: 52px;
        height: 52px;
        @apply relative bg-white;
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);

        &--bg-color {
          @apply bg-blue-800;
        }

        &--bg-grey-400 {
          @apply bg-grey-400;
        }

        &--no-box-shadow {
          box-shadow: none;
        }

        &__sup-icon {
          @apply flex items-center justify-center absolute bg-secondary;
          width: 24px;
          height: 24px;
          right: -7px;
          top: -4px;
          border: 2px solid #ffffff;
          box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
        }

        &__sup-icon--new {
          @apply flex items-center justify-center absolute;
          width: 24px;
          height: 24px;
          right: -7px;
          top: -4px;
        }
      }
    }

    &__header__full {
      @apply w-full bg-white flex;
      box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08),
        inset 0px -1px 0px rgba(229, 229, 229, 0.8);

      &-left {
        @apply border-r border-grey-400 flex justify-between;
        width: 40%;
        padding: 12px 20px 0px 41px;
      }

      &-right {
        @apply flex justify-between relative flex-1;
        padding: 10px 25px;

        &--label-container {
          @apply flex overflow-x-auto;
          max-width: 115px;

          &::-webkit-scrollbar {
            display: none;
          }

          @media screen and (min-width: 500px) and (max-width: 1500px) {
            max-width: 275px;
          }

          @media screen and (min-width: 1500px) and (max-width: 1600px) {
            max-width: 275px;
          }

          @media screen and (min-width: 1601px) and (max-width: 1900px) {
            max-width: 375px;
          }

          @media screen and (min-width: 1901px) {
            max-width: 450px;
          }
        }
      }
    }

    .channelbody {
      @apply flex;
      height: calc(100% - 79px);
    }

    &__body {
      @apply flex;
      height: calc(100% - 88px);

      &__filter-section {
        height: 52px;
        box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08),
          inset 0px -1px 0px rgba(229, 229, 229, 0.8);
      }

      &__section-left {
        width: 39.8%;
        padding: 20px 35px 20px 40px;
        @apply h-full border-r border-grey-400;

        @media screen and (max-width: 1500px) {
          width: calc(36% + 18px);
        }

        @media screen and (min-width: 1500px) {
          // width: calc(35% + 6px);
          width: calc(31% + 2px);
          // width: min-content;
        }
        &.lg {
          @media screen and (max-width: 1499px) {
            width: 27%;
            min-width: 27%;
          }

          @media screen and (min-width: 1500px) {
            width: calc(31% + 50px);
            // min-width: calc(31% + 70px);
          }
        }
        &.ebox {
          padding: 0px;
          max-width: 461px;
        }

        &.box {
          @apply p-5;
        }

        .droppable-empty-container {
          border: 3px solid rgba(230, 228, 225, 0.2);
          border-color: rgb(230 228 225 / var(--tw-border-opacity));
          border-style: dashed;
          border-radius: 5px;
          height: 150px;
          padding: 0 13px;
          margin: 0 -13px;
          margin-top: 5px;
        }

        &__header {
          @apply bg-white;
          padding: 12px 20px 0px 41px;
          box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08),
            inset 0px -1px 0px rgba(229, 229, 229, 0.8);

          .assigned-to {
            @apply flex items-center;
            // max-width: 130px;

            .selected-user {
              @apply mr-2 truncate;
              white-space: nowrap;
              max-width: 90px;

              @media screen and (min-width: 1600px) {
                max-width: max-content;
              }
            }

            @media screen and (min-width: 1600px) {
              max-width: max-content;
              margin-right: 10px;
            }
          }

          .channels-btn {
            @apply border-radius-20 flex items-center relative cursor-pointer mb-3;
            border: 1px solid rgba(230, 228, 225, 0.2);
            max-width: 150px;
            min-width: 115px;

            .selected__text {
              @apply text-13 font-medium ml-1 truncate mr-2;
              max-width: 80px;

              @media screen and (min-width: 1600px) {
                max-width: max-content;
              }
            }

            @media screen and (min-width: 1600px) {
              max-width: max-content;
            }
          }
        }

        &__body {
          padding: 20px 35px 20px 40px;
          height: calc(100% - 28px);
          // scroll-behavior: smooth;
          &::-webkit-scrollbar-thumb {
            display: none;
          }
          &:hover::-webkit-scrollbar-thumb {
            display: block;
          }

          .due-filter-buttons {
            @apply font-medium text-[13px] rounded-[10px] bg-grey-300 text-grey-900;
            padding: 4px 12px;
            width: auto;
            border: 1px solid rgba(230, 228, 225, 0.2);

            &.active {
              @apply bg-primary text-white border border-primary;
            }
          }

          .task-section {
            .task-header {
              @apply font-medium text-xs text-grey-700 uppercase mb-2;
            }
          }
        }
      }

      &__setting-height {
        height: 108%;
      }

      &__section-right {
        @apply relative h-full flex-1;
        width: 60%;

        .type-list {
          @apply transition-colors ease-in-out duration-700;
          display: flex;
          border-radius: 8px;
          align-items: center;
          padding: 8px;
          cursor: pointer;
        }

        .groups-list {
          @apply transition-colors ease-in-out mt-3 gap-2 duration-700;
          display: flex;
          align-items: center;
        }

        .type-list:hover,
        .active {
          @apply bg-grey-55 transition-colors ease-in-out duration-700;

          .image {
            background: #ffffff;
          }
        }

        .image {
          background: #eaf3f6;
          border-radius: 5px;
          padding: 9px;
        }

        .card {
          &-container {
            padding: 20px 35px;
            @apply min-h-full bg-white;

            .video-container {
              @apply w-full;
              height: 335px;
            }
          }

          &-container--new {
            @apply bg-white;
            padding: 30px 35px 35px;
            border: 1px solid rgba(230, 228, 225, 0.6);
            box-shadow: 0px 4px 22px rgba(85, 83, 80, 0.1);
            border-radius: 14px;
          }

          &-heading {
            &.sticky {
              position: sticky;
              top: -26px;
              width: calc(100% + 70px);
              margin-left: -35px;
              padding: 18px 0 18px 35px;
              @apply bg-white;
              box-shadow: 0px 2px 4px rgba(85, 83, 80, 0.08);
              transition: all 300ms ease-in;
              // border-top-left-radius: 14px;
              // border-top-right-radius: 14px;
            }
          }
        }

        .email-provider-list {
          @apply flex items-center px-4 py-3 bg-white border border-grey-400 cursor-pointer;
          border-radius: 12px;
          top: 659px;

          /* White */

          background: #ffffff;
          /* Gray/400 */

          border: 1px solid #e6e4e1;
          border-radius: 8px;

          &__avatar {
            @apply flex items-center justify-center flex-shrink-0;
            width: 50px;
            height: 50px;
            background-color: #0491ab;
            border-radius: 12px;

            > img {
              @apply object-contain;
            }

            &--g-suite {
              background-color: #4d8ffd;
            }

            &--microsoft {
              background-color: #dc3e15;
            }

            &--email {
              background-color: #0491ab;
            }
          }

          &.active,
          &:hover {
            @apply border-secondary;
            box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
              0px 10px 12px rgba(85, 83, 80, 0.12);
            transition: all 300ms;
          }

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }

        .account-plan-list {
          @apply flex items-center px-4 py-3 border border-grey-400 cursor-pointer;
          border-radius: 12px;
          border: 1px solid #e6e4e1;
          border-radius: 8px;

          &.active,
          &:hover {
            @apply border-secondary;
            box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
              0px 10px 12px rgba(85, 83, 80, 0.12);
            transition: all 300ms;
          }

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }

        &.layout-1 {
          padding: 35px 3.3%;
          @apply bg-grey-200 overflow-y-auto;

          @media screen and (max-width: 1500px) {
            padding: 35px 8%;
          }

          @media screen and (min-width: 1500px) {
            padding: 35px 10%;
          }
        }
      }

      &channel-body {
        height: calc(100% - 79px);
      }

      &__gap {
        grid-gap: 10px;
      }

      &__user {
        @apply flex text-center;
        background: #00abba;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        justify-content: center;
        padding-top: 8px;
        border: 2px solid #fff;
        color: #fff;
      }

      &__user-add {
        border: 2px solid #fff;
        position: absolute;
        left: 90px;
        border-radius: 25rem;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 8px;
        color: #fff;
      }
    }

    &--box-card {
      @apply bg-white border border-grey-400 rounded-xl w-full cursor-pointer;
      min-width: 343px;
      height: 175px;

      &:hover {
        @apply border-secondary;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      &__header {
        @apply text-lg font-semibold;
      }

      &__desc {
        @apply text-sm text-grey-800 mt-2;
      }
    }

    .box-title {
      @media screen and (max-width: 1919px) {
        font-size: 36px !important;
      }
      @media screen and (min-width: 1920px) {
        font-size: 40px !important;
      }
    }
  }

  &__emoji-container {
    @apply bg-white absolute;
    margin-left: -5px;
    box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
      0px 10px 12px rgba(85, 83, 80, 0.12);
    z-index: 999 !important;
    width: 520px;
    height: 324px;
    overflow-y: hidden;
    border-radius: 10px;
  }

  &__emoji-dropdown {
    @apply bg-white absolute;
    z-index: 999 !important;
    box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
      0px 10px 12px rgba(85, 83, 80, 0.12);
    width: 476px;
    height: calc(100% - 170px);
    overflow-y: hidden;
    border-radius: 10px;

    .dashboard__emoji-list {
      height: 100%;
      overflow-y: hidden;
    }

    .dashboard__emoji-list::after {
      content: '';
      display: none;
    }

    .dashboard__emoji-icon {
      @apply cursor-pointer;
      font-size: inherit;
    }
  }

  &__emoji-list {
    height: 170px;
    overflow-y: auto;
  }

  &__emoji-list::after {
    content: '';
    display: flex;
    width: 98%;
    height: 10%;
    left: 0;
    position: absolute;
    z-index: 9999999;
    margin-left: auto;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.7) 40%,
      #fff 95%
    );
  }

  &__emoji-icon {
    @apply cursor-pointer;
    font-size: inherit;
    width: 22px;

    &:hover {
      background: #eaf3f6;
      border-radius: 6px;
    }
  }

  &__overflow-y-scroll {
    overflow-y: scroll;
    height: 230px;
  }

  &__overflow-y-scroll::after {
    content: '';
    display: flex;
    width: 98%;
    height: 10%;
    left: 0;
    position: absolute;
    z-index: 9999999;
    margin-left: auto;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.7) 40%,
      #fff 95%
    );
  }
}

.sticky {
  position: sticky;
  top: 0px;
  width: 100%;
  margin-left: 0px;
  padding: 18px 0 18px 35px;
  @apply bg-white;
  box-shadow: 0px 2px 4px rgba(85, 83, 80, 0.08);
  transition: all 300ms ease-in;
  z-index: 999 !important;
  // border-top-left-radius: 14px;
  // border-top-right-radius: 14px;
}

.chat-message {
  &-container {
    @apply flex flex-col bg-white p-0;
    padding: 0;
  }

  &-header {
    padding: 13.2px 20px;
    background: #ffffff;
    box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08),
      inset 0px -1px 0px #e5e5e5;

    &-user-heading {
      border-radius: 30px;
      height: 38px;

      &.hovered,
      &.clicked {
        @apply px-4;
        border: 1px solid #e5e5e5;
        box-shadow: 0px 3px 6px -2px rgba(85, 83, 80, 0.15);
      }
    }

    &-button {
      background: #005c6f;
      border-radius: 6px;
      padding: 2px 11px;

      &-icon {
        fill: white;
        width: 14px;
        height: 14px;
      }

      &.clicked {
        background: #eef7fc;
        color: #005c6f;

        .chat-message-header-button-icon {
          fill: #005c6f;
        }
      }
    }

    &-button:hover {
      box-shadow: 0px 5px 6px -2px rgba(85, 83, 80, 0.3);
    }
  }

  &-body {
    @apply flex-1 p-5 overflow-auto;
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &:hover::-webkit-scrollbar-thumb {
      display: block;
    }
  }

  &-list {
    margin-bottom: 25px;

    .bot-conversation {
      a {
        text-decoration: underline;
        color: #357af5;
      }
    }

    &--sm {
      margin-bottom: 0px;
    }

    p {
      margin-bottom: 4px;
    }

    .email-body {
      &.sender {
        background: #f8faff;
      }
      background: #ffffff;
      border-radius: 10px;
      padding: 15px;

      .item {
        padding: 11px 0px;
        border-bottom: 1px solid #e5e5e5;

        p {
          margin-bottom: -4px;
        }

        &:last-child {
          border-bottom: 0px;
        }

        &:first-child {
          padding: 0px 0px 11px;
        }

        &__attachment {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px;

          .secondary-icon {
            width: 0px;
            visibility: hidden;

            &__visible {
              visibility: visible;
              width: 16px;
              transition: all 300ms ease-in;
            }
          }

          .attachment-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background: #ffffff;
            border: 1px solid #e6e4e1;
            width: 47%;
            padding: 8px 12px;
            border-radius: 8px;
          }

          div:hover {
            box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
              0px 12px 30px -4px rgba(85, 83, 80, 0.25);
          }
        }
      }
    }

    .email-body--webform {
      background: #ffffff;
      border-radius: 10px;
      padding: 20px 25px 25px;
    }

    .email-body--webform > div:last-child {
      margin-bottom: 0px;
    }

    &--sender {
      @apply flex-row-reverse;

      .content {
        @apply flex items-end flex-col;
      }

      &__email {
        background: #f4f9f9;
        border: 1px solid rgba(0, 0, 0, 0.05);
      }

      p {
        text-align: left;
      }
    }

    .flip-card {
      width: 100%;
      height: 100%;
      perspective: 1500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flip-card-inner {
      width: 100%;
      height: 100%;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    .flip-card-active {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; /* New */
      flex-grow: 1; /* New */
    }

    .flip-card-front {
      z-index: 2;
    }

    .flip-card-back {
      transform: rotateY(180deg);
    }
  }

  &-footer {
    @apply mx-5 my-4;
    border: 1px solid #e6e4e1;
    border-radius: 10px;
    margin-top: 0;
    // max-height: 380px;
    // min-height: 380px;

    .footer-loader {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      z-index: 1;
      backdrop-filter: blur(1px);
    }
    &.footer-expanded {
      height: calc(100vh - 240px);
      max-height: calc(100vh - 240px);

      .ql-editor {
        // min-height: 150px;
        // height: calc(100vh - 402px) !important;
      }
    }

    &__body {
      height: 120px;
      // height: calc(100% - 36px);
    }

    &__body--whatsApp {
      height: 177px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }

    &__header {
      @apply flex items-center justify-between bg-grey-300 px-4 py-2;
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;

      &__reminder {
        background: #ffffff;
      }

      .pointer {
        cursor: pointer;
      }
    }

    &__footer {
      @apply py-2 px-2 text-xs font-medium;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .button {
      background: #ffffff;
      border: 1px solid rgba(53, 122, 245, 0.2);
      border-radius: 6px;
      cursor: pointer;

      &__active {
        @apply border border-primary;
        background: #ffffff;
        box-shadow: 0px 0px 0px 1.5px rgba(103, 89, 214, 0.25);
        border-radius: 6px;
      }
    }

    .button:hover {
      background: #f8fbff;
      .box-task-input {
        background: #f8fbff;
      }
    }

    textarea {
      background: transparent;
    }

    &__reminder {
      background-color: #f8faff;
      border-color: #e6e4e1;
    }

    &__note {
      background-color: #fff8ec;
      border-color: #fdf7da;

      .ps {
        background: rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        font-size: 12px;
      }
    }

    .email-input {
      background: #ffffff;
      border-radius: 10px;
      padding: 15px;
      padding-bottom: 0px;

      .item {
        padding-bottom: 11px;
        border-bottom: 1px solid #e5e5e5;

        p {
          margin-bottom: -4px;
        }

        &:last-child {
          border-bottom: 0px;
        }

        &__user-name {
          white-space: pre;
          margin: 0px 2px;

          &:last-child {
            margin-right: 0px;
            padding-bottom: 0px;
          }
        }

        &__user-name:hover {
          background: #eef7fc;
          border-radius: 6px;
          padding: 4px;
        }

        input {
          @apply relative font-medium;
          color: #292929;
          margin-bottom: -4px;
          background: #ffffff;
          transition: all 300ms ease-in;
          outline: none;
        }

        &__attachment-wrapper {
          border-radius: 8px;
        }

        &__attachment {
          gap: 10px;

          .file-icon {
            width: 24px;
          }

          .attachment-item {
            border: 1px solid #e6e4e1;
            width: 47%;
            border-radius: 8px;
            font-weight: 500;

            .secondary-icon {
              width: 0px;
              visibility: hidden;

              &__visible {
                visibility: visible;
                width: 16px;
                transition: all 300ms ease-in;
              }
            }
          }

          .attachment-item:hover {
            box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
              0px 12px 30px -4px rgba(85, 83, 80, 0.25);
          }
        }
      }
    }
  }
}

.snackbarWrap {
  visibility: hidden;
  min-width: 200px;
  max-width: 200px;
  // margin-left: -125px;
  // background-color: #333;
  // color: #fff;
  // text-align: center;
  // border-radius: 2px;
  // padding: 16px;
  position: fixed;
  z-index: 9999999;
  // left: 50%;
  // bottom: 30px;
  top: 40px;
  right: 40px;
  font-size: 1rem;
  opacity: 0;

  &__sm {
    min-width: 200px;
    max-width: 200px;
  }

  &__lg {
    min-width: 200px;
    max-width: 300px;
  }
}

.snackbarWrap.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: opacity 300ms ease-in 100ms;
}

.snackbarWrap.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.snackbarWrap {
  .snackbar_content {
    @apply fixed flex items-center text-center px-2 py-2 bg-black;
    border-radius: 20px;
    min-width: 200px;
    max-width: 200px;

    &__sm {
      min-width: 200px;
      max-width: 200px;
    }

    &__lg {
      min-width: 200px;
      max-width: 300px;
    }

    .symbol {
      display: flex;
    }

    .message {
      @apply text-white font-medium;
      margin-left: 8px;
      letter-spacing: -0.0024em;
      font-size: 15px;
      line-height: 21px;
    }
  }
}

// .snackbar {
//   @apply fixed flex items-center text-center px-3 py-2 bg-black;
//   left: 74%;
//   top: 10%;
//   min-width: 140px;
//   height: 40px;
//   transform: translate(-50%, -50%);
//   border-radius: 20px;
//   box-shadow: 0px 8px 14px rgba(85, 83, 80, 0.25);
// }

// .snackbar .symbol {
//   // flex: 20%;
//   // margin-right: 8px;
// }

// .snackbar .message {
//   font-size: 15px;
//   @apply font-medium ml-2;
//   // flex: 80%;
//   // text-align: center;
// }

#show {
  visibility: visible;
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}

#hide {
  visibility: hidden;
}

.user-name {
  white-space: pre;
  margin: 0px 2px;

  &:last-child {
    margin-right: 0px;
  }
}

.user-name:hover {
  background: #eef7fc;
  border-radius: 6px;
  padding: 4px;
}

.close-icon {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #005c6f;
  padding: 2px;
}

.footer-icons {
  align-self: center;
  box-shadow: 0px 0px 13px -2px rgba(85, 83, 80, 0.1),
    0px 10px 12px -2px rgba(85, 83, 80, 0.12);
  border-radius: 14px;

  .item {
    width: 102px;
    height: 70px;
    justify-content: center;

    img {
      width: 18px;
      align-self: center;
      margin-bottom: 6px;
    }

    &__label {
      visibility: hidden;
      background: #357af5;
      border-radius: 4px;
      color: #ffffff;
      padding: 2px 5px;
      top: -15px;
      position: relative;
      opacity: 0;

      &__visible {
        visibility: visible;
        opacity: 1;
        transition: all 500ms ease-in;
      }

      p {
        font-size: 9px;
        text-transform: uppercase;
      }
    }
  }

  .item:hover {
    background: #eef7fc;
    border-radius: 10px;
    transition: all 500ms ease-in;
  }
}

.bg-color-on-hover {
  @apply relative cursor-pointer;
  z-index: 1;

  &::after {
    content: '';
    width: calc(100% + 30px);
    @apply absolute h-full bg-grey-30 top-0 opacity-0 invisible;
    left: -15px;
    border-radius: 10px;
    z-index: -1;
    transition: opacity 300ms ease-in;
  }

  &--w-none {
    &::after {
      width: unset;
    }
  }

  &--br-6 {
    &::after {
      @apply rounded-md;
    }
  }

  &--br-12 {
    &::after {
      @apply rounded-xl;
    }
  }

  &:hover::after,
  &.active::after,
  &.active {
    @apply opacity-100 visible;
  }

  &--w-100 {
    &::after {
      width: 100%;
      left: 0px;
    }
  }
  &--md {
    &::after {
      width: calc(100% + 80px);
      left: -27px;
    }
  }

  &--sm {
    &::after {
      width: calc(100% + 24px);
      left: -12px;
    }

    &__left {
      &::after {
        width: calc(100% + 12px);
        left: -12px;
      }
    }
    &__left-ttd {
      &::after {
        width: calc(100% + 2px);
        left: -1px;
      }
    }
  }

  &--xs {
    &::after {
      width: calc(100% + 10px);
      left: -5px;
      border-radius: 4px;
    }
  }
  &--xxs {
    &::after {
      width: calc(100% + 5px);
      border-radius: 4px;
    }
  }

  &--err {
    &::after {
      width: calc(100% + 8px);
      left: -5px;
      border: none;
      border-width: 0;
      border-radius: 12px;
      background: rgba(240, 68, 56, 0.06);
    }
  }

  &--bg-grey-30 {
    &::after {
      @apply bg-grey-30;
    }
  }
  .pencil_icon_on_stage_name {
    display: none;
  }
  &:hover {
    .pencil_icon_on_stage_name {
      display: block;
      transition: opacity 300ms ease-in;
    }
  }
}

.showIcon {
  &:hover {
    visibility: visible;
  }
}

.general__settings__main__header {
  padding: 14px 32px 14px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(230, 228, 225, var(--tw-border-opacity));
}

.enquiries-progress-bar {
  width: 15.4%;
  @apply fixed bg-white pt-3 pb-4 px-4;
  left: 88px;
  bottom: 16px;
  box-shadow: 0px 4px 30px rgba(85, 83, 80, 0.15);
  border-radius: 14px;
  z-index: 98;
  .progress-bar {
    &-container {
      height: 6px;
      background-color: #eef1f3;
      border-radius: 30px;
    }
    &-slider {
      border-radius: 30px;
    }
  }
  &__tooltip-container {
    @apply absolute bg-grey-900;
    bottom: 71px;
    right: 10px;
    padding: 12px 10px;
    border-radius: 6px;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    z-index: 12;
    transition: all 300ms;
    &::after {
      content: '';
      @apply absolute bg-grey-900;
      width: 10px;
      height: 10px;
      left: calc(50% - 5px);
      bottom: -4px;
      transform: rotate(-45deg);
    }
  }
  &:hover & {
    &__tooltip-container {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.conversation-back-svg {
  svg {
    path {
      stroke: #ffffff;
    }
  }
}

.toolboxheader {
  path {
    @apply stroke-primary fill-primary;
  }
  rect {
    @apply stroke-primary fill-primary;
  }
}

.channels-icon {
  path {
    @apply stroke-primary fill-primary;
  }
  rect {
    @apply stroke-primary fill-primary;
  }
}

.rhap_progress-indicator {
  background-color: #ffffff !important;
}
.rhap_progress-filled {
  background-color: #ce4961 !important;
}
.rhap_volume-indicator {
  background-color: #ffffff !important;
  opacity: 1 !important;
  z-index: 3;
}
.rhap_volume-filled {
  background-color: #ce4961 !important;
}
.rhap_total-time {
  margin-left: 12px !important;
}
.rhap_volume-button {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.dashboard-ebox-intro-video {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  margin-left: auto;
  margin-right: auto;

  &__hide-video {
    font-size: 13px;
    color: #7e84c7;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 12px;
    text-align: right;
  }
}

.webform-conversation-wrapper {
  font-weight: 400;
  font-size: 0.875rem;
  border: 1px solid #e6e4e1;
  border-radius: 8px;
  color: #292929;
  padding: 10px 12px;
  min-height: 41px;
}

.webform-input-line-break {
  overflow-wrap: anywhere;
}
