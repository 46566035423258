.tabel-upload-history {
  @apply w-full mt-4;
  thead {
    th {
      @apply text-sm text-grey-800 text-left font-normal;
      padding: 4px 10px;
    }
  }
  tbody {
    tr {
      &:hover {
        @apply bg-grey-55 rounded-lg;
      }
      td {
        @apply text-sm;
        padding: 8px 10px;
      }
    }
  }
}
