.search {
  &-container {
    @apply flex items-center;
    &.is-open {
      .search {
        &-input-wrapper {
          width: 530px;
          @apply opacity-100 visible;
          transition: width 300ms ease-in, opacity 300ms ease-in;
        }
        &-close-icon {
          @apply inline-block;
        }
        &-icon {
          @apply hidden;
        }
      }
    }
  }
  &-input-wrapper-sm {
    width: 250px;
    @apply opacity-100 visible;
    transition: width 300ms ease-in, opacity 300ms ease-in;
  }
  &-input {
    padding-left: 44px;
    font-size: 15px;
    @apply text-grey-900;
    border: 1.5px solid #e6e4e1;
    &:focus {
      @apply border-[1.5px] border-primary;
      box-shadow: 0px 0px 0px 1.5px rgba(103, 89, 214, 0.25);
    }
    &::placeholder {
      font-size: 15px;
      @apply font-semibold;
    }
    &-wrapper {
      width: 0;
      @apply opacity-0 invisible;
    }
  }
  &-icon {
    @apply cursor-pointer;
    width: 18px;
    height: 18px;
  }
  &-close-icon {
    @apply cursor-pointer hidden;
  }
}
