.contact-tasks {
  padding: 30px 40px;
  height: calc(100% - 140px);
  max-height: calc(100% - 140px);
  overflow-y: auto;
  &__list {
    padding: 12px 20px;
    background: #f8faff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    border: 1px solid transparent;
    border-radius: 10px;
    transition: all 300ms;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:hover {
      @apply border border-primary;
      .dots-action-btn {
        opacity: 1;
        visibility: visible;
      }
    }
    &__left {
      padding-right: 20px;
    }
    &__right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }
    .dots-action-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background-color: transparent;
      border-radius: 4px;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      &:hover {
        background-color: #fff;
      }
    }
    &.active {
      @apply border border-primary;
    }
    .input-field__textarea {
      border: 1px solid transparent;
      border-radius: 10px;
    }
  }
  &__done-list {
    padding: 12px 20px;
    background: #fbfbfb;
    border-radius: 10px;

    &:not(:first-child) {
      margin-top: 16px;
    }
    &__msg {
      @apply text-grey-700;
      font-size: 13px;
      padding: 12px 15px;
      letter-spacing: -0.0024em;
      margin-top: 10px;
      background: #ffffff;
      border-radius: 10px;
    }
    &__left {
      padding-right: 20px;
    }
    &__right {
      flex-shrink: 0;
    }
  }
  .toggle-check-btn {
    @apply inline-block text-grey-700 text-sm font-semibold;
    padding: 4px 9px;
    background: rgba(255, 255, 255, 0.76);
    border: 1.5px solid #c4c4c4;
    border-radius: 6px;
    transition: all 300ms;
    cursor: pointer;
    &:hover {
      @apply border-[1.5px] border-primary;
    }
    &--static {
      cursor: initial;
      &:hover {
        border: 1px solid #c4c4c4;
      }
    }
    &__input {
      display: none;
      &:checked + label {
        @apply bg-primary text-white border-[1.5px] border-primary;
      }
    }
    &-container {
      margin: 0 8px 8px 0;
    }
  }
}
.task-complete-circle {
  @apply relative flex-shrink-0 cursor-pointer;
  width: 25px;
  height: 25px;
  > svg {
    position: absolute;
    left: 0;
    top: 0;
  }
  &__check {
    opacity: 0;
    display: none;
  }
  &:hover .task-complete-circle__outline {
    opacity: 0;
    display: none;
  }
  &:hover .task-complete-circle__check {
    opacity: 1;
    display: inline-block;
  }
}
