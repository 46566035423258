.files {
  @apply overflow-x-clip overflow-y-auto h-full;
  padding-bottom: 50px;
  &__header {
    padding: 25px 8px 30px 40px;

    &--image-container {
      display: flex;
      &::-webkit-scrollbar {
        height: 7px;
      }
    }
  }
  &__more {
    @apply flex justify-center items-center bg-grey-300 rounded-lg;
    width: 95px;
    height: 98px;
  }
  &__body {
    padding: 0px 26px;
  }
  &__card {
    @apply cursor-pointer;
    padding: 12px 14px;
    margin-bottom: 2px;
    &--right-side {
      @apply bg-white rounded-md;
      width: 26px;
      height: 26px;
      padding: 11.5px 7px;
    }
    &:hover {
      @apply bg-grey-55 rounded-lg;
      .files__card--right-side {
        @apply opacity-100 visible;
      }
    }
  }
  &__cancel {
    @apply text-xs cursor-pointer;
    margin-top: 2px;
  }
}
