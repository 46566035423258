.product-update-video-container {
  width: 100%;
  height: 390px;
  border-radius: 14px;
  @apply border border-grey-400;
}
.product-description {
  ul {
    list-style-type: disc !important;
    margin-left: 30px;
  }
  li {
    list-style: inherit !important;
  }
}
.toolbox {
  &__body {
    @apply flex items-start;
  }
  &__navigation {
    width: 25%;
    .chats-preview__title {
      @apply font-semibold;
    }
    .chats-preview__msg {
      font-size: 15px;
      @apply text-grey-800 font-normal mt-1;
    }
    &.channels-navigation {
      width: 35%;
      @media screen and (min-width: 1500px) {
        width: calc(34% - 30px);
      }
    }
  }
  &__main {
    flex: 1;
    padding: 28px 38px;
    .tabs {
      &-header {
        font-size: 15px;
        @apply font-medium;
        &__active {
          font-size: 15px;
        }
      }
    }
    &.channels {
      background-color: #fcfcfc;
      height: 100%;

      .connect-new {
        background: #ffffff;
        margin: auto;
        width: 620px;
        box-shadow: 0px 4px 22px 0px #5553501a;
        padding: 30px 50px;
        border-radius: 15px;
        padding-bottom: 12px;
      }
    }
  }
  &__aside {
    width: 25%;
    padding: 22px 25px 22px 32px;
    @apply border-l border-grey-400 bg-grey-200;
    .btn {
      &--cancel {
        @apply text-sm;
        color: #e14660;
        background: #fcf0f2;
        border: 1px solid #e14660;
        border-radius: 6px;
        letter-spacing: -0.0026em;
      }
    }
    .label-coming-soon {
      @apply flex items-center justify-center text-white font-semibold uppercase  bg-primary rounded-md;
      font-size: 9px;
      padding: 3px 6px;
      line-height: initial;
    }
  }
}

.label-coming-soon {
  @apply flex items-center justify-center text-white font-semibold uppercase  bg-primary rounded-md;
  font-size: 9px;
  padding: 3px 6px;
  line-height: initial;
}

.table-sms-plans {
  thead {
    tr {
      @apply bg-grey-55 border-b border-grey-500;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      td {
        @apply text-sm font-bold;
        padding: 10px 15px;
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }
  }
  tbody {
    tr {
      @apply border-b border-grey-400;
      td {
        @apply text-sm font-medium;
        padding: 10px 15px;
        &:first-child {
          @apply font-bold;
        }
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border: none;
      }
    }
  }
  .btn {
    &--cancel {
      color: #fff;
      background-color: #f03636;
      border: 1px solid transparent;
    }
    &--upgrade,
    &--signup {
      color: #ce4961;
      border: 1px solid #ce4961;
    }
    &--xs {
      padding: 3px 12px;
      border-radius: 5px;
    }
    &--downgrade {
      color: #f03636;
      border: 1px solid #f03636;
    }
  }
  &-wrapper {
    @apply border border-grey-500 rounded-lg;
  }
}
