.channels-new-wrapper {
  // margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  .channels-item {
    background: #ffffff;
    box-shadow: 0px 2.49px 4.981px 0px rgba(0, 0, 0, 0.18);
    width: 300px;
    height: 195px;
    margin-right: 10px;
    margin-bottom: 15px;
    border-radius: 12px;
    padding: 15px 16px;
    position: relative;
    @media screen and (max-width: 1680px) {
      width: 256px;
    }
    .channel-icon {
      width: 62px;
      height: 62px;
      background-color: aqua;
    }
    .channel-title {
      @apply font-semibold;
      font-size: 16px;
      margin-top: 10px;
    }
    .channel-description {
      @apply font-normal;
      font-size: 14px;
      margin-top: 10px;
    }
    .paid-on {
      @apply font-semibold text-primary border border-primary;
      position: absolute;
      right: 10px;
      font-size: 8px;
      border-radius: 10px;
      padding: 3px 9px;
      background: #ecfcff;
    }
    .coming-soon {
      @apply font-semibold;
      position: absolute;
      right: 10px;
      color: #006aff;
      font-size: 8px;
      border: 1px solid #006aff;
      border-radius: 10px;
      padding: 3px 9px;
      background: #f4f9ff;
    }
    .channel-connect {
      display: none;
      position: absolute;
      background: black;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.5);
    }
    &.active {
      .channel-connect {
        display: flex;
        .connect-button {
          @apply text-primary font-semibold;
          padding: 5px 14px;
          border-radius: 8px;
          background: #ffffff;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
}
