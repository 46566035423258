.swal-button {
  @apply bg-primary;
}

.swal-button--confirm {
  @apply bg-primary;
}

.swal-button:not([disabled]):hover {
  @apply bg-primary;
}

.swal-sender-email {
  .swal-text {
    font-size: 15px;
    line-height: inherit;
    width: 422px;
    text-align: center;
  }
}
