.custom-radio-button {
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #636566;

  @apply relative bg-white bg-transparent cursor-pointer;
  transition: all 300ms ease-out;
  border-radius: 8px;
  &::after {
    content: '';
    width: 14px;
    height: 10px;
    @apply absolute opacity-0 invisible;
    top: 3px;
    right: 1px;
  }
  &:checked {
    @apply border-4 border-primary;
    &::after {
      @apply opacity-100 visible;
    }
    .custom-radio-button__label {
      @apply text-grey-900;
    }
  }
  &--sm {
    width: 16px;
    height: 16px;
    &::after {
      top: 2px;
      right: 0;
    }
  }
  &__label {
    font-size: 13px;
    @apply font-medium text-grey-800;
    margin-left: 5px;
  }
}

.radio-input-container {
  display: block;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 2px solid #908f8d;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkmark {
    background-color: #ce4961;
    border: 2px solid #ce4961;

    &:after {
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
}
