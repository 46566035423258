.box-settings {
  &__automation-card {
    @apply border border-grey-400 bg-grey-300 border-radius-14 relative;

    &::after {
      @apply bg-primary;
      content: '';
      z-index: 0;
      width: 1px;
      height: 24px;
      position: absolute;
      left: 50%;
      bottom: -25px;
    }

    &.last {
      &::after {
        content: unset !important;
      }
    }
    .edit-then {
      @apply text-primary;
      position: absolute;
      top: 13px;
      right: 14px;
      font-weight: 600;
      font-size: 14px;
    }
    .box-automation-notes {
      @apply border-grey-400 bg-white;
      border-radius: 5px;
    }

    .box-task-input {
      background: white;
      &:hover {
        background: #f8fbff;
      }
    }
  }
  &__automation-template {
    &__automation-text {
      font-family: 'SF Pro Text';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: -0.0024em;
      color: #4c5254;
    }
    &__automation-stuff {
      @apply border border-grey-400 bg-grey-300 border-radius-14 relative;
    }
  }
  &.webform {
    .ql-editor {
      min-height: 300px !important;
    }
  }
  &.facebook .ql-editor {
    min-height: 150px !important;
  }
  .ql-insertStyles {
    display: flex !important;
    align-items: center !important;
  }
}
