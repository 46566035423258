.custom-tooltip {
  position: relative;
  display: inline-block;

  &__type2 {
    position: relative;
    display: inline-block;

    .tooltiptext {
      @apply text-grey-900 font-medium;
      visibility: hidden;
      position: absolute;
      width: fit-content;
      background-color: #ffffff;
      z-index: 99999;
      border: 0.5px solid #e6e4e1;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);

      &__right {
        top: 8px;
        left: 110%;
      }
    }
  }

  &__type2:hover .tooltiptext {
    visibility: visible;
  }

  &__type2 .tooltiptext__right::after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-color: transparent transparent transparent transparent;
  }
}

.custom-tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: fit-content;
  background-color: #ce4961;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  z-index: 1;
  padding: 10px;
  width: max-content;

  &__drag-drop-box {
    border-bottom-left-radius: 0px;
  }

  &__email {
    left: 40%;
    width: fit-content;
  }

  &__left {
    top: -5px;
    bottom: auto;
    right: 128%;
    padding: 8px 20px 8px 10px;
  }

  &__bottom {
    top: 135%;
    left: 50%;
    // margin-left: -60px;
    transform: translateX(-50%);
  }
  &__bottom-left {
    top: 125%;
    left: -115%;
    max-width: 205px;
  }
  &__right {
    top: -5px;
    left: 125%;
  }

  &__top {
    bottom: 125%;
    left: 50%;
    // margin-left: -50px;
    transform: translateX(-50%);
  }

  &__top--new {
    bottom: 102%;
    left: -10%;
  }

  &__toggle {
    bottom: 27px;
    margin-left: 0;
    right: -25px;
  }

  &__chat-footer {
    bottom: 100%;
    left: 50%;
    margin-left: -109px;
    width: max-content;
    padding: 5px 10px;
  }

  &__sms-variable {
    bottom: 100%;
    left: 50%;
    margin-left: -90px;
    width: max-content;
    padding: 5px 10px;
  }

  &__left--delete-icon {
    padding: 5px 10px;
    top: -15px;
    right: 128%;
    width: 274px;
  }

  &__top--delete-icon {
    padding: 5px 10px;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    width: 274px;
  }

  &__invisible {
    visibility: hidden !important;
    opacity: 0;
  }
}

.custom-tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.custom-tooltip .tooltiptext__top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #ce4961 transparent transparent transparent;
}

.custom-tooltip .tooltiptext__pointer-adjust::after {
  top: 100%;
  left: 46%;
  margin-left: -5px;
  border-color: #ce4961 transparent transparent transparent;
}

.custom-tooltip .tooltiptext__drag-drop-box::after {
  top: 100%;
  left: 0%;
  margin-left: 0px;
  border-color: #ce4961 transparent transparent #ce4961;
  border-width: 4px;
}

.custom-tooltip .tooltiptext__top--delete-icon::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #ce4961 transparent transparent transparent;
}

.custom-tooltip .tooltiptext__chat-footer::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #ce4961 transparent transparent transparent;
}

.custom-tooltip .tooltiptext__sms-variable::after {
  top: 100%;
  left: 41%;
  margin-left: -5px;
  border-color: #ce4961 transparent transparent transparent;
}

.custom-tooltip .tooltiptext__top--new::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent transparent transparent;
}

.custom-tooltip .tooltiptext__left--delete-icon::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent #ce4961;
}

.custom-tooltip .tooltiptext__left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent #ce4961;
}

.custom-tooltip .tooltiptext__bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #ce4961 transparent;
}
.custom-tooltip .tooltiptext__bottom-left::after {
  bottom: 100%;
  left: 85%;
  margin-left: -5px;
  border-color: transparent transparent #ce4961 transparent;
}
.custom-tooltip .tooltiptext__right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent #ce4961 transparent transparent;
}

.custom-tooltip .tooltiptext__toggle::after {
  top: 123%;
  right: 50%;
  margin-top: -5px;
  border-color: #ce4961 transparent transparent transparent;
}

.custom-tooltip:hover .tooltiptext {
  visibility: visible;
}
