.authentication-module {
  @apply w-full flex items-center flex-col h-full overflow-y-auto;
  // min-height: 100vh;

  // background: #14171c;

  // background: linear-gradient(302.7deg, rgba(100, 1, 239, 0.2) 42.39%, rgba(0, 0, 0, 0) 157.66%), #14171c;

  background: #dab8b5;
  position: relative;
  .back_shadow {
    position: absolute;
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
    width: 824px;
    height: 90%;
    opacity: 0.6;
    filter: blur(475px);
    -webkit-filter: blur(475px);
    -moz-filter: blur(475px);
    -ms-filter: blur(475px);
    -o-filter: blur(475px);
    -webkit-backdrop-filter: blur(475px);
    background: #e56757;
    // backdrop-filter: blur(475px);
    border-radius: 824px;
    @media screen and (max-width: 500px) {
      width: 100%;
      height: 90%;
    }
    @supports (-moz-appearance: none) {
      opacity: 0.3 !important;
    }
    // for safari
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        backdrop-filter: blur(475px);
        opacity: 0.7;
      }
    }
    // @media screen and (-webkit-min-device-pixel-ratio: 0) {
    // }
  }
  // .sce {
  //   @apply w-full h-full flex items-center flex-col py-12;
  //   min-height: 100vh;
  //   overflow: auto;
  //   // opacity: 0.6;
  //   // filter: blur(474.526px);
  //   background: linear-gradient(302.7deg, rgba(100, 1, 239, 0.2) 42.39%, rgba(0, 0, 0, 0) 157.66%), #14171c;
  // }
  @media screen and (max-width: 1500px) {
    zoom: 0.9;
  }
  .img-vector {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  &__container-sm {
    @apply bg-white;
    box-shadow: 0px 8px 34px -4px rgba(85, 83, 80, 0.1);
    border-radius: 20px;
    width: 502px;
    max-height: 100%;
    padding: 45px 50px;
    @screen xxl {
      padding: 62px 120px;
    }
  }
  &__container {
    width: 640px;
    max-height: 100%;
    @apply bg-white;
    box-shadow: 0px 8px 34px -4px rgba(85, 83, 80, 0.1);
    border-radius: 20px;
    padding: 45px 120px 40px;
    @screen xxl {
      padding: 62px 120px;
    }
    .or-text {
      @apply w-full flex items-center justify-center;
      border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      > span {
        @apply flex items-center justify-center relative text-grey-600 font-medium z-10;
        min-width: 32px;
        height: 18px;
        font-size: 10px;
        top: 9px;
        border: 1px solid #e6e4e1;
        border-radius: 20px;
      }
    }
  }
  &__heading {
    color: #292929;
    @apply font-bold;
  }
  &__one-click {
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 30%;
      top: 14px;
      height: 1px;
      left: 0px;
      background: #e5e5e5;
    }
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 30%;
      top: 14px;
      height: 1px;
      right: 0px;
      background: #e5e5e5;
    }
  }
  &.post-login {
    .authentication-module__container {
      padding: 60px 100px 80px;
    }
  }
}

.business_icon_wrapper {
  left: 18px;
}

.gradient-text {
  background: linear-gradient(
    160deg,
    #40009a -23.26%,
    #1d00ff -23.24%,
    #f4bf32 67.28%,
    #dd347a 99.88%,
    rgba(217, 217, 217, 0) 150.58%,
    #dd347a 150.58%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 5s normal forwards ease-in-out infinite;
}

@-webkit-keyframes textShine {
  0% {
    background-position: left;
  }
  30% {
    background-position: right;
  }
  50% {
    background-position: left;
  }
  80% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

@keyframes textShine {
  0% {
    background-position: left;
  }
  30% {
    background-position: right;
  }
  50% {
    background-position: left;
  }
  80% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
